<!--支付页面-->
<template>
  <div class="pageF6Color">
    <HeadGoBackVue iCustomizdde="true">
      <template #default>
        <span> {{ $fanyi("支付方式选择") }} </span>
      </template>
    </HeadGoBackVue>
    <loadingVue v-if="loading" />
    <div class="pageF6ColorBox" v-if="operationSteps == 1">
      <div class="topUpAmount whiteCard">
        <h5>{{ $fanyi("待支付金额") }}</h5>
        <p>
          {{ $fun.JPYNumSegmentation(pendingPaymentAmount) }}
          <span>円</span>
        </p>
        <span class="paymentOrderNumber"
          >{{ $route.query.order_sn ? $fanyi("订单号") : $fanyi("配送单号") }}
          {{ $route.query.order_sn || $route.query.porder_sn }}</span
        >
      </div>
      <div
        class="internationalDeposit whiteCard"
        v-if="
          !(
            userSelectedPaymentMethod == -1 || userSelectedPaymentMethod == 1
          ) && paymentPagePreviewData.predict_logistics_price
        "
      >
        <div class="dip" @click="changePredictLogisticsPriceIsPay">
          <input type="checkbox" :checked="predict_logistics_price_is_pay" />
          <span
            >予想国際送料
            <span style="color: #b4272b">{{
              paymentPagePreviewData.predict_logistics_price
            }}</span>
            円 も一緒に入金</span
          >
        </div>
      </div>
      <ul class="paymentMethods">
        <h5>{{ $fanyi("选择支付方式") }}</h5>
        <li
          @click="paymentMethodSelection(1)"
          v-if="
            paymentPagePreviewData.pay_amount_jpy <=
            paymentPagePreviewData.user_balance +
              paymentPagePreviewData.user_credit
          "
        >
          <div class="payIcon">
            <img :src="require('@/assets/icon/yuezhifu.png')" alt="" />
          </div>
          <span>{{ $fanyi("余额支付") }}</span>
          <input type="checkbox" :checked="userSelectedPaymentMethod == 1" />
        </li>

        <li>
          <div class="payIcon">
            <img :src="require('@/assets/icon/yinhangzhifu.png')" alt="" />
          </div>
          <span>{{ $fanyi("银行支付") }}</span>
        </li>
        <li
          v-for="paymentMethodItem in payMethodListData"
          :key="paymentMethodItem.key"
          @click="paymentMethodSelection(paymentMethodItem.key)"
        >
          <div class="payIcon"></div>
          <span>{{ $fanyi(paymentMethodItem.value) }}</span>
          <input
            type="checkbox"
            :checked="userSelectedPaymentMethod == paymentMethodItem.key"
          />
        </li>
        <li @click="paymentMethodSelection(5)">
          <div class="payIcon">
            <img :src="require('@/assets/icon/payPal.png')" alt="" />
          </div>
          <span>{{ $fanyi("paypal支付") }}</span>
          <input type="checkbox" :checked="userSelectedPaymentMethod == 5" />
        </li>
      </ul>
      <button class="payBtn" @click="nextStep()">
        {{ $fanyi("跳转下一步") }}
      </button>
    </div>
    <confirmPayment v-else-if="operationSteps == 2" ref="confirmPaymentRef" />
    <paymentSuccess v-else-if="operationSteps == 3" />
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, watch, computed, provide } from "vue";
import HeadGoBackVue from "@/components/headGoBack";
import confirmPayment from "./confirmPayment";
import paymentSuccess from "./paymentSuccess.vue";
import loadingVue from "../../components/loading";
const { proxy } = getCurrentInstance();
//------------------------ data -----------------------------------------
const payMethodListData = ref(); //订单可选支付方式列表
const paymentPagePreviewData = ref({}); //支付页面预览数据
const operationSteps = ref(1); //页面状态 1=选择支付方式 2=确认付款 3=支付成功
const userSelectedPaymentMethod = ref(-1); // 用户选择的支付方式
const predict_logistics_price_is_pay = ref(false);
const loading = ref(true); //加载中
const pendingPaymentAmount = computed(() => {
  // // <!-- total_amount返回的是日元 限制最低支付金额用另一个参数 -->

  // 没有选择支付方式和余额支付的时候,如果用户支付后余额+用户赊账额度大于等于0,就显示订单金额,否则显示最低入金金额
  // 如果是paypal支付和银行支付就显示最低入金金额
  let juTiNum = 0;
  // 根据用户选择支付方式显示待支付金额

  if (
    userSelectedPaymentMethod.value == -1 ||
    userSelectedPaymentMethod.value == 1
  ) {
    juTiNum =
      (paymentPagePreviewData.value?.balance_availability || 0) +
        paymentPagePreviewData.value.user_credit >
      0
        ? paymentPagePreviewData.value.pay_amount_jpy
        : paymentPagePreviewData.value.payPal_pay_amount_min;
  } else if (userSelectedPaymentMethod.value == 5) {
    // papal支付
    juTiNum = paymentPagePreviewData.value.payPal_pay_amount_min || 1;
  } else {
    // 银行支付;
    juTiNum = paymentPagePreviewData.value.bank_pay_amount_min || 1;
  }

  return juTiNum || 0;
}); //待支付金额

//------------------------ methods --------------------------------------
//选择支付方式
const paymentMethodSelection = (i) => {
  if (userSelectedPaymentMethod.value == i) {
    userSelectedPaymentMethod.value = -1;
  } else {
    userSelectedPaymentMethod.value = i;
  }

  if ([-1, 1].includes(userSelectedPaymentMethod.value)) {
    if (predict_logistics_price_is_pay.value) {
      changePredictLogisticsPriceIsPay();
    }
  }
};
// 获取订单可选支付方式列表
const listOfPaymentOptionsForOrder = () => {
  proxy.$api.payMethodList().then((res) => {
    ////console.log('事件名',res)
    if (res.code != 0) return this.$message.error(res.data.msg);
    //接下来的操作
    payMethodListData.value = res.data;
    //接口返回数据没有图标也没有分类,所以只取银行数据,余额支付和paypal是一直有的
    payMethodListData.value = payMethodListData.value.filter(
      (obj) => obj.is_bank != 0
    );
  });
};
// 订单页面支付数据获取
const orderPayment = () => {
  proxy.$api
    .orderPayment({
      order_sn: proxy.$route.query.order_sn,
      discounts_id: proxy.$route.query.discounts_id,
    })
    .then((res) => {
      loading.value = false;
      if (res.code != 0) {
        if (proxy.$route.query.step != 3 && proxy.$route.name == "payment") {
          proxy.$message.error(proxy.$fanyi(res.msg));
        }
        return (paymentPagePreviewData.value = {});
      }
      //接下来的操作
      paymentPagePreviewData.value = res.data;
      if (proxy.$refs.confirmPaymentRef) {
        proxy.$refs.confirmPaymentRef.paymentForm.pay_amount =
          !!paymentPagePreviewData.value.balance_availability &&
          paymentPagePreviewData.value.balance_availability > 0
            ? paymentPagePreviewData.value.pay_amount_jpy
            : paymentPagePreviewData.value.payPal_pay_amount_min;
      }
      //2023/5/12 当用户余额足够时也需要用户支付1日元
      if (!paymentPagePreviewData.value.bank_pay_amount_min) {
        paymentPagePreviewData.value.bank_pay_amount_min = 1;
      }
    });
};
// 获取配送单支付预览数据
const storagePOrderPayment = () => {
  proxy.$api
    .storagePOrderPayment({
      porder_sn: proxy.$route.query.porder_sn,
      discounts_id: proxy.$route.query.discounts_id,
    })
    .then((res) => {
      loading.value = false;
      ////console.log('事件名',res)
      if (res.code != 0) {
        if (proxy.$route.query.step != 3 && proxy.$route.name == "payment") {
          proxy.$message.error(proxy.$fanyi(res.msg));
        }
        return (paymentPagePreviewData.value = {});
      }
      //接下来的操作
      paymentPagePreviewData.value = res.data;
      if (proxy.$refs.confirmPaymentRef) {
        proxy.$refs.confirmPaymentRef.paymentForm.pay_amount =
          !!paymentPagePreviewData.value.balance_availability &&
          paymentPagePreviewData.value.balance_availability > 0
            ? paymentPagePreviewData.value.pay_amount_jpy
            : paymentPagePreviewData.value.payPal_pay_amount_min;
      }

      //2023/5/12 当用户余额足够时也需要用户支付1日元
      if (!paymentPagePreviewData.value.bank_pay_amount_min) {
        paymentPagePreviewData.value.bank_pay_amount_min = 1;
      }
    });
};
// 获取支付页面预览数据
const getPaymentPagePreviewData = () => {
  console.log("执行getPaymentPagePreviewData");
  // 根据路由判断订单支付或者配送单支付
  if (proxy.$route.query.order_sn) {
    orderPayment();
  } else {
    storagePOrderPayment();
  }
  listOfPaymentOptionsForOrder();
};
// 下一步
const nextStep = () => {
  if (userSelectedPaymentMethod.value == -1) {
    // 如果用户没有选中支付方式
    proxy.$message.warning(proxy.$fanyi("请选择支付方式"));
  } else if (userSelectedPaymentMethod.value == 5) {
    // 如果选择了paypal支付
    loading.value = true;
    let formData = {
      pay_amount:
        paymentPagePreviewData.value.balance_availability &&
        paymentPagePreviewData.value.balance_availability > 0
          ? paymentPagePreviewData.value.pay_amount_jpy
          : paymentPagePreviewData.value.payPal_pay_amount_min, // 2023/10/12 h5改成余额没有欠款的情况下使用订单金额,有欠款的情况下使用最低入金金额
      discounts_id: proxy.$route.query.discounts_id,
    };
    let apiName = "";
    // paypal支付是跳转接口返回的网址
    if (proxy.$route.query.order_sn) {
      apiName = "orderPayPalPayOrder";
      formData.order_sn = proxy.$route.query.order_sn;
    } else if (proxy.$route.query.porder_sn) {
      apiName = "porderPayPalPayOrder";
      formData.porder_sn = proxy.$route.query.porder_sn;
    }
    up_statistical_data();

    proxy.$api[apiName](formData).then((res) => {
      loading.value = false;
      ////console.log('事件名',res)
      if (res.code != 0)
        return proxy.$message.error(
          proxy.$fanyi(res.msg) || proxy.$fanyi("请更换其他支付方式")
        );
      //接下来的操作
      if (res.data.pay_url && res.data.pay_url.substr(0, 4) == "http") {
        location.href = res.data.pay_url;
      } else {
        proxy.$message.error(
          proxy.$fanyi(res.msg) || proxy.$fanyi("请更换其他支付方式")
        );
      }
    });
  } else {
    console.log("执行nextStep");
    // 如果是银行卡支付
    operationSteps.value++;
    let querydata = JSON.parse(JSON.stringify(proxy.$route.query));
    querydata.step = operationSteps.value;
    querydata.userSelectedPaymentMethod = userSelectedPaymentMethod.value;
    proxy.$router.push({
      path: "/payment",
      query: querydata,
    });
  }
};

// 上传统计数据到统计网站
const up_statistical_data = () => {
  return;
  // let up_arr = JSON.parse(localStorage.getItem("paySubmitGoodsList")); //前面订单详情页面保存的数据
  // if (up_arr) {
  //   proxy.$fun.statisticsNumberOfOrders(up_arr, "pay");
  // }
};

provide("up_statistical_data", up_statistical_data);

const changePredictLogisticsPriceIsPay = () => {
  console.log("执行changePredictLogisticsPriceIsPay");

  predict_logistics_price_is_pay.value = !predict_logistics_price_is_pay.value;

  let showBalancePayStatus =
    paymentPagePreviewData.value.user_balance +
      paymentPagePreviewData.value.user_credit -
      paymentPagePreviewData.value.pay_amount_jpy >
    0; // 如果余额+用户赊账额度-订单金额大于0

  // 产品说和pc做成一样的
  if (predict_logistics_price_is_pay.value === true) {
    paymentPagePreviewData.value.pay_amount_jpy =
      paymentPagePreviewData.value.pay_amount_jpy +
      paymentPagePreviewData.value.predict_logistics_price;

    if (!showBalancePayStatus) {
      paymentPagePreviewData.value.payPal_pay_amount_min +=
        paymentPagePreviewData.value.predict_logistics_price;
      paymentPagePreviewData.value.bank_pay_amount_min +=
        paymentPagePreviewData.value.predict_logistics_price;
    }
  } else {
    paymentPagePreviewData.value.pay_amount_jpy -=
      paymentPagePreviewData.value.predict_logistics_price;
    // 产品说和pc做成一样的
    // 如果余额不够支付就
    if (!showBalancePayStatus) {
      paymentPagePreviewData.value.payPal_pay_amount_min -=
        paymentPagePreviewData.value.predict_logistics_price;

      paymentPagePreviewData.value.bank_pay_amount_min -=
        paymentPagePreviewData.value.predict_logistics_price;
    }
  }
};
//------------------------ pageLoad -------------------------------------
watch(
  () => proxy.$route.query.step,
  (newValue) => {
    if (proxy.$route.query.step) {
      operationSteps.value = proxy.$route.query.step;
    } else {
      operationSteps.value = 1;
    }
    // getPaymentPagePreviewData();
  },
  { immediate: true }
);
getPaymentPagePreviewData();
defineExpose({
  paymentPagePreviewData,
  userSelectedPaymentMethod,
  loading,
  pendingPaymentAmount,
});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.pageF6ColorBox {
  padding: 0px 30px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.whiteCard {
  margin-top: 20px;
  padding: 60px 0;
  background: #ffffff;
  border-radius: 6px;
}
.topUpAmount {
  h5 {
    font-size: 24px;
    text-align: center;
    line-height: 1;
    color: #999999;
    margin-bottom: 29px;
  }
  p {
    text-align: center;
    font-size: 56px;
    color: #b4272b;
    font-weight: 600;
    span {
      font-size: 24px;
      font-weight: 400;
      margin-left: 8px;
    }
  }
  .paymentOrderNumber {
    margin-top: 29px;
    font-size: 24px;

    font-weight: 400;
    color: #000000;
    text-align: center;
    display: block;
  }
}
.internationalDeposit {
  span {
    font-weight: 500;
    font-size: 24px;
  }
}
.paymentMethods {
  background: #ffffff;
  border-radius: 6px;
  background: #ffffff;
  margin-top: 15px;
  padding: 30px;
  h5 {
    font-size: 24px;
  }
  li {
    margin: 42px 0;
    &:last-child {
      margin-bottom: 0;
    }
    display: flex;
    align-items: center;
    .payIcon {
      width: 30px;
      flex: 0 0 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      img {
        width: 100%;
      }
    }
    span {
      font-size: 28px;
      font-weight: 600;
    }
    input {
      pointer-events: none;
      margin-left: auto;
      border: solid 1px #dfdfdf;
      $size: 30px;
      flex: 0 0 $size;
      width: $size;
      height: $size;
      border-radius: 50%;
    }
  }
}
.payBtn {
  margin-top: auto;
  position: fixed;
  bottom: 50px;
  margin: 0 auto;
  width: 690px;
  height: 70px;
  background: #b4272b;
  border-radius: 6px;
  font-size: 28px;
  color: #ffffff;
}
</style>
