<!-- 支付成功页面 -->
<template>
  <div>
    <div class="paymentSuccessBox">
      <img
        class="successIcon"
        :src="require('@/assets/icon/chenggong.png')"
        alt=""
      />
      <p
        class="successFont"
        v-if="['2', '3', '4'].includes($route.query.userSelectedPaymentMethod)"
      >
      <p> 入金報告操作成功</p> 
      <p>※ご入金がまだ完了しておりません。お客様がご指定した銀行に入カされた金額と口座名義でお振込みをお願いいたします。</p>
      <p>※入金反映には少々お時間が掛かります。何度も同じ操作を繰り返されますと、入金確認待ち金額が累積されますので、実際の入金額と一致せず、調査に時間が掛かってしまいますので、１回のみでお願いします。</p>
     
      </p>
      <p class="successFont" v-else>
        {{ $fanyi("支付成功") }}
      </p>
      <p class="paymentAmount">
        {{ $fun.JPYNumSegmentation($route.query.pay_amount) }}
        <span class="danwei">円</span>
      </p>
      <div class="transactionNumber">
        <p v-if="$route.query.order_sn">
          <span class="title">
            {{ $fanyi("订单号") }}
          </span>
          <span class="con">{{ $route.query.order_sn }}</span>
        </p>
        <p v-else>
          <span class="title">
            {{ $fanyi("配送单号") }}
          </span>
          <span class="con">{{ $route.query.porder_sn }}</span>
        </p>
        <p>
          <span class="title">
            {{ $fanyi("交易流水号") }}
          </span>
          <span class="con">{{ $route.query.serial_number }}</span>
        </p>
      </div>

      <button
        class="operationButton red"
        @click="$fun.routerToPage('/user/index')"
      >
        {{ $fanyi("回到个人中心") }}
      </button>
      <button class="operationButton" @click="$fun.routerToPage('/')">
        {{ $fanyi("回到首页") }}
      </button>
    </div>
  </div>
  <div class="technicalSupport" :class="{ successCss: step == 3 }">
    {{ $fanyi("由1688提供部分技术服务⽀持") }}
  </div>
</template>
<script setup>
import { getCurrentInstance, ref } from "vue";
const { proxy } = getCurrentInstance();
//------------------------ data -----------------------------------------
//------------------------ pageLoad -------------------------------------
//------------------------ methods --------------------------------------
defineExpose({});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.paymentSuccessBox {
  width: 690px;
  background: #ffffff;
  border-radius: 6px;
  padding: 60px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto 0;

  .successIcon {
    width: 80px;
    height: 80px;
    margin-bottom: 41px;
  }

  .successFont {
    font-size: 32px;
    color: #000000;
    line-height: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    p{
      font-size: 27px;
      line-height: 35px;
      text-align: left;
      &:first-child{
        font-size: 32px;
        margin-bottom: 15px;
        font-weight: bold;
        text-align: center;
      }
    }
  }

  .paymentAmount {
    margin-top: 30px;
    font-size: 56px;

    font-weight: 600;
    color: #b4272b;

    .danwei {
      font-size: 24px;
      font-weight: 400;
    }
  }

  .transactionNumber {
    margin-top: 60px;
    padding: 42px 0px;
    width: 100%;

    p {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 42px;

      &:last-child {
        margin-bottom: 0;
      }

      .title {
        font-size: 28px;
        color: #999999;
        flex: 0 0 197px;
        text-align: center;
      }

      .con {
        flex: 1;
        font-size: 28px;
        color: #000000;
      }
    }
  }

  .operationButton {
    width: 100%;
    height: 70px;
    background: #ffffff;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    font-size: 28px;
    margin-top: 30px;
    color: #000000;
  }

  .operationButton.red {
    margin-top: 120px;
    background: #b4272b;
    color: #ffffff;
  }
}
.technicalSupport {
  background: #f6f6f6;
  padding: 30px 0 59px;
  font-size: 20px;
  color: #999999;
  line-height: 1;
  text-align: center;
}
</style>
