<!--确认支付页面-->
<template>
  <!-- 余额支付 -->
  <div class="pageF6ColorBox" v-if="userSelectedPaymentMethod == 1">
    <div class="topUpAmount">
      <h5>{{ $fanyi("待支付金额") }}</h5>
      <p>
        {{ $fun.JPYNumSegmentation($parent.pendingPaymentAmount) }}
        <span>円</span>
      </p>
      <span class="paymentOrderNumber" v-if="$route.query.order_sn"
        >{{ $fanyi("订单号") }} {{ $route.query.order_sn }}</span
      >
      <span class="paymentOrderNumber" v-else
        >{{ $fanyi("配送单号") }} {{ $route.query.porder_sn }}</span
      >
    </div>
    <div class="accountBalanceInformation">
      <p>
        <span class="title">{{ $fanyi("账户当前可用余额") }}</span>
        <span class="price"
          ><b>{{
            $fun.JPYNumSegmentation(paymentPagePreviewData.user_balance || 0)
          }}</b>
          円</span
        >
      </p>
      <p>
        <span class="title">{{ $fanyi("付款后剩余") }}</span>
        <span class="price"
          ><b>{{
            $fun.JPYNumSegmentation(
              paymentPagePreviewData.user_balance -
                $parent.pendingPaymentAmount || 0
            )
          }}</b>
          円</span
        >
      </p>
    </div>
    <button class="payBtn" @click="useBalancePayment">
      {{ $fanyi("确认付款") }}
    </button>
  </div>
  <!-- 银行支付 -->
  <div class="pageF6ColorBox" v-else-if="userSelectedPaymentMethod != 5">
    <div class="paymentInfoBox">
      <img
        :src="require('@/assets/icon/bank1.png')"
        alt=""
        v-if="userSelectedPaymentMethod == 2"
        class="bankImg"
      />
      <img
        :src="require('@/assets/icon/bank2.png')"
        alt=""
        v-if="userSelectedPaymentMethod == 3"
        class="bankImg"
      />
      <img
        :src="require('@/assets/icon/bank3.png')"
        alt=""
        v-if="userSelectedPaymentMethod == 4"
        class="bankImg"
      />
      <div class="remittanceBank">
        <div class="left" v-if="userSelectedPaymentMethod == 2">
          <p>
            <span class="title">{{ $fanyi("銀行名") }}：</span>
            <span>楽天銀行</span>
          </p>
          <p>
            <span class="title">{{ $fanyi("支店番号") }}：</span>
            <span>252</span>
          </p>
          <p>
            <span class="title">{{ $fanyi("支店名") }}：</span>
            <span>第二営業支店</span>
          </p>
          <p>
            <span class="title">{{ $fanyi("預金種目") }}：</span>
            <span>普通預金</span>
          </p>
          <p>
            <span class="title">{{ $fanyi("口座番号") }}：</span>
            <span>7977668</span>
          </p>
          <p>
            <span class="title">{{ $fanyi("口座名義") }}：</span>
            <span>ラクマート（カ）</span>
          </p>
        </div>
        <div class="left" v-if="userSelectedPaymentMethod == 4">
          <p>
            <span class="title">{{ $fanyi("銀行名") }}：</span>
            <span>paypay銀行</span>
          </p>
          <p>
            <span class="title">{{ $fanyi("支店番号") }}：</span>
            <span> 005</span>
          </p>
          <p>
            <span class="title">{{ $fanyi("支店名") }}：</span>
            <span> ビジネス営業部</span>
          </p>
          <p>
            <span class="title">{{ $fanyi("預金種目") }}：</span>
            <span>普通預金</span>
          </p>
          <p>
            <span class="title">{{ $fanyi("口座番号") }}：</span>
            <span> 1928861</span>
          </p>
          <p>
            <span class="title">{{ $fanyi("口座名義") }}：</span>
            <span>ラクマート（カ）ジャパン</span>
          </p>
        </div>
        <div class="left" v-if="userSelectedPaymentMethod == 3">
          <p>
            <span class="title">{{ $fanyi("銀行名") }}：</span>
            <span> ゆうちょ銀行</span>
          </p>
          <p>
            <span class="title">{{ $fanyi("支店番号") }}：</span>
            <span>四〇八支店</span>
          </p>

          <p>
            <span class="title">{{ $fanyi("口座番号") }}：</span>
            <span>5631876</span>
          </p>
          <p>
            <span class="title">{{ $fanyi("口座名義") }}：</span>
            <span>ラクマート（カ）</span>
          </p>
          <p><span> ゆうちょ銀行からのお振込</span></p>
          <p><span>14040-56318761</span></p>
        </div>
        <button class="copyBrn" @click="copyInformation">
          {{ $fanyi("复制银行信息") }}
        </button>
      </div>
      <div class="depositInformation">
        <div class="minimumDepositAmount">
          <span
            ><span class="title">{{ $fanyi("待支付金额") }}：</span
            ><b>
              <!-- {{ paymentPagePreviewData.bank_pay_amount_min }} -->
              {{
                $fun.JPYNumSegmentation(
                  $parent.paymentPagePreviewData.pay_amount_jpy
                )
              }}
            </b>
            <span class="danwei"> 円</span></span
          >
          <span
            ><span class="title">{{ $fanyi("最低入金金额") }}：</span
            ><b>
              {{
                $fun.JPYNumSegmentation(
                  paymentPagePreviewData.bank_pay_amount_min
                )
              }}
              <!-- {{ $fun.JPYNumSegmentation($parent.pendingPaymentAmount) }} -->
            </b>
            <span class="danwei"> 円</span></span
          >
        </div>
        <van-form class="paymentFormBox">
          <van-cell-group inset>
            <van-field
              v-model="paymentForm.pay_reach_date"
              readonly
              name="pay_reach_date"
              label="入金日"
              @click="showCalendar = true"
            />
            <van-calendar
              :title="$fanyi('选择日期')"
              v-model:show="showCalendar"
              @confirm="onConfirm"
            />
            <van-field
              label="入金名義"
              v-model="paymentForm.pay_name"
              name="pay_name"
              placeholder=""
            />
            <van-field
              label="入金额"
              v-model="paymentForm.pay_amount"
              @change="setInputAmount"
              name="pay_amount"
              placeholder=""
            >
              <template #input>
                <div class="paymentAmount van-cell">
                  <input
                    type="text"
                    oninput="value=value.replace(/[^0-9.]/g,'')"
                    v-model="paymentForm.pay_amount"
                    @change="amountInputRestriction(paymentForm, 'pay_amount')"
                  />
                  <span class="yuan">円</span>
                </div>
              </template>
            </van-field>
            <van-field
              label="備考欄"
              v-model="paymentForm.pay_remark"
              name="pay_remark"
              placeholder=""
              class="optionalField"
            >
              <template #input>
                <textarea v-model="paymentForm.pay_remark" />
              </template>
            </van-field>
          </van-cell-group>
        </van-form>
        <div class="RemittanceNotes">
          <h3>{{ $fanyi("注意") }}</h3>
          <p>１、銀行入金する金額を入力して下さい。</p>
          <p>
            ２、入金報告時（画面）に、入金額と入金報告額は一致させるようお願いします。複数注文の場合は、まず残高をチャージしてからお支払いを行うことをお勧めします。
          </p>
          <p>
            ３、入金名義について、必ず入金報告提出時と同じなるようにお願い致します。違う場合は特定出来ず、入金反映が著しく遅延してしまいます。
          </p>
          <p class="importantReminder">
            ※タイミングによっては為替変動により残高不足となります。
            その場合、注文書は実行されませんので、少々多めのご入金額設定をお勧め致します。】
          </p>
        </div>
      </div>
    </div>
    <button class="bankPayment payBtn" @click="confirmBankPayment">
      {{
        ["2", "3", "4"].includes($route.query.userSelectedPaymentMethod)
          ? "提出"
          : "支払い"
      }}
    </button>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, computed, onMounted, inject } from "vue";
import { getCurrentDay } from "@/utlis/date.js";
const { proxy } = getCurrentInstance();
//------------------------ data -----------------------------------------
const showCalendar = ref(false); //选择日期弹窗开关
// 支付方式
const userSelectedPaymentMethod = computed(() => {
  return (
    proxy.$route.query.userSelectedPaymentMethod ||
    proxy.$parent.userSelectedPaymentMethod
  );
});
const setInputAmount = () => {
  if (!paymentForm.value.pay_amount || paymentForm.value.pay_amount == 0) {
    paymentForm.value.pay_amount = 1;
  }
};
// 支付预览数据
const paymentPagePreviewData = computed(() => {
  return proxy.$parent.paymentPagePreviewData;
});
//支付表单
const paymentForm = ref({
  pay_reach_date: getCurrentDay(), //入金日
  pay_amount:
    !!paymentPagePreviewData.value.balance_availability &&
    paymentPagePreviewData.value.balance_availability > 0
      ? paymentPagePreviewData.value.pay_amount_jpy
      : paymentPagePreviewData.value.payPal_pay_amount_min, //入金额
  pay_remark: "", //備考欄
  pay_name: "", //入金名義
});

//------------------------ methods --------------------------------------
const up_statistical_data = inject("up_statistical_data");

// 复制银行信息
const copyInformation = () => {
  let text = "";
  switch (proxy.$route.query.userSelectedPaymentMethod) {
    case "2":
      text = `
銀行名：楽天銀行
支店番号：252
支店名：第二営業支店
預金種目：普通預金
口座番号：7977668
口座名義：ラクマート（カ）
      `;
      break;
    case "3":
      text = `
銀行名：paypay銀行（旧）ジャパンネット銀行
支店番号：005
支店名：ビジネス営業部
預金種目：普通預金
口座番号：1928861
口座名義：ラクマート（カ）ジャパン

      `;
      break;
    case "4":
      text = `
銀行名：ゆうちょ銀行
支店名：四〇八支店
口座番号：5631876
口座名義：ラクマート（カ）ゆうちょ銀行からのお振込 14040-56318761
      `;
      break;
    default:
      break;
  }
  navigator.clipboard.writeText(text);
  proxy.$message.primary(proxy.$fanyi("复制成功"));
};
// 限制输入框只能输入数字
const amountInputRestriction = (data, name) => {
  if (isNaN(data[name])) {
    data[name] = 0;
    proxy.$message.warning("请输入数字");
  }
};
// 确认选择日期
const onConfirm = (val) => {
  paymentForm.value.pay_reach_date = getCurrentDay(val);
  showCalendar.value = false;
};

// 使用余额付款
const useBalancePayment = () => {
  proxy.$parent.loading = true;
  up_statistical_data();
  // 判断是否是配送单
  if (proxy.$route.query.porder_sn) {
    // 配送单支付
    proxy.$api
      .storagePOrderBalancePayment({
        porder_sn: proxy.$route.query.porder_sn,
        discounts_id: proxy.$route.query.discounts_id,
      })
      .then((res) => {
        proxy.$parent.loading = false;
        ////console.log('事件名',res)
        if (res.code != 0)
          return proxy.$message.error(proxy.$fanyi("支付失败"));
        //接下来的操作
        let querydata = JSON.parse(JSON.stringify(proxy.$route.query));
        querydata.serial_number = res.data.serial_number;
        querydata.pay_amount = res.data.pay_amount_jpy || res.data.pay_amount;
        proxy.$router.push({
          path: "/payment",
          query: querydata,
        });
        querydata.step = 3;
      });
  } else {
    // 订单支付
    proxy.$api
      .balancePayOrder({
        order_sn: proxy.$route.query.order_sn,
        discounts_id: proxy.$route.query.discounts_id,
      })
      .then((res) => {
        proxy.$parent.loading = false;
        ////console.log('事件名',res)
        if (res.code != 0)
          return proxy.$message.error(proxy.$fanyi("支付失败"));
        //接下来的操作
        let querydata = JSON.parse(JSON.stringify(proxy.$route.query));
        querydata.serial_number = res.data.serial_number;
        querydata.pay_amount = res.data.pay_amount_jpy || res.data.pay_amount;
        querydata.step = 3;
        proxy.$router.push({
          path: "/payment",
          query: querydata,
        });
      });
  }
};

// 使用银行卡付款
const confirmBankPayment = () => {
  // 校验表单必填项
  let checklist = ["pay_reach_date", "pay_amount", "pay_name"];
  for (let i in checklist) {
    if (!paymentForm.value[checklist[i]]) {
      return proxy.$message.warning(proxy.$fanyi("请填写必填信息"));
    }
  }
  //   拼装提交数据
  let submitData = {
    pay_bank_method: proxy.$route.query.userSelectedPaymentMethod,
    pay_reach_date: paymentForm.value.pay_reach_date,
    pay_name: paymentForm.value.pay_name,
    pay_amount: paymentForm.value.pay_amount,
    pay_remark: paymentForm.value.pay_remark,
    discounts_id: proxy.$route.query.discounts_id,
  };
  let urlStr = "bankPayOrder";
  // 如果是订单支付就把银行支付订单的api名字拼接上去并设置提交表单的order_sn
  // 如果是配送单支付就把银行支付配送单的api名字拼接上去并设置提交表单的porder_sn
  if (proxy.$route.query.order_sn) {
    urlStr = "bankPayOrder";
    submitData.order_sn = proxy.$route.query.order_sn;
  } else {
    urlStr = "storagePOrderBankPayment";
    submitData.porder_sn = proxy.$route.query.porder_sn;
  }
  up_statistical_data();
  proxy.$api[urlStr](submitData).then((res) => {
    proxy.$parent.loading = false;
    if (res.code != 0) return proxy.$message.warning(proxy.$fanyi(res.msg));
    let querydata = JSON.parse(JSON.stringify(proxy.$route.query));
    querydata.serial_number = res.data.serial_number;
    querydata.pay_amount = res.data.pay_amount_jpy || res.data.pay_amount;
    querydata.step = 3;
    proxy.$router.push({
      path: "/payment",
      query: querydata,
    });
  });
};

//------------------------ pageLoad -------------------------------------
setTimeout(() => {
  // userSelectedPaymentMethod.value = 3;
  let userpayMethAccound = (
    proxy.$store.state.userInfo || { user_pay_account: [] }
  ).user_pay_account;
  userpayMethAccound.forEach((usPaAc) => {
    if (
      usPaAc.bill_method == proxy.$route.query.userSelectedPaymentMethod &&
      usPaAc.is_default == 1
    ) {
      paymentForm.value.pay_name = usPaAc.pay_account;
    }
  });
}, 100);
defineExpose({ paymentForm });
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.pageF6ColorBox {
  padding: 0px 30px;
  display: flex;
  flex-direction: column;
  min-height: unset !important;
}

.topUpAmount {
  margin-top: 20px;
  padding: 60px 0;
  background: #ffffff;
  border-radius: 6px;

  h5 {
    font-size: 24px;
    text-align: center;
    line-height: 1;
    color: #999999;

    margin-bottom: 29px;
  }

  p {
    text-align: center;
    font-size: 56px;
    color: #b4272b;
    font-weight: 600;
    span {
      font-size: 24px;
      font-weight: 400;
      margin-left: 8px;
    }
  }

  .paymentOrderNumber {
    margin-top: 29px;
    font-size: 24px;

    font-weight: 400;
    color: #000000;
    text-align: center;
    display: block;
  }
}

.accountBalanceInformation {
  margin-top: 15px;
  width: 690px;
  background: #ffffff;
  border-radius: 6px;
  padding: 30px;

  p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 42px;

    &:last-child {
      margin-bottom: 0;
    }

    .title {
      font-size: 28px;

      font-weight: 400;
      color: #999999;
    }

    .price {
      font-size: 28px;

      font-weight: 600;

      b {
        color: #b4272b;
        font-weight: 600;
        font-size: 28px;
      }
    }
  }
}

.payBtn {
  margin-top: auto;
  position: fixed;
  bottom: 50px;
  margin: 0 auto;
  width: 690px;
  height: 70px;
  background: #b4272b;
  border-radius: 6px;
  font-size: 28px;
  color: #ffffff;
}

.payBtn.bankPayment {
  position: sticky;
  bottom: 0;
  margin-bottom: 20px;
}

.paymentInfoBox {
  width: 690px;
  background: #ffffff;
  border-radius: 6px;
  margin: 20px auto 45px;
  padding: 50px 30px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .bankImg {
    width: 262px;
    height: 44px;
    margin-bottom: 63px;
  }

  .remittanceBank {
    display: flex;
    align-items: flex-end;
    width: 100%;
    padding-bottom: 36px;
    border-bottom: solid 1px #dfdfdf;

    .left {
      flex: 1;

      p {
        width: 100%;
        display: flex;
        align-items: flex-start;
        line-height: 24px;
        margin-bottom: 22px;

        &:last-child {
          margin-bottom: 0;
        }

        span {
          font-size: 24px;
          font-weight: 400;
          line-height: 24px;
        }

        .title {
          color: #999999;
          white-space: nowrap;
        }
      }
    }

    .copyBrn {
      width: 240px;
      height: 60px;
      background: #b4272d;
      border-radius: 6px;
      font-size: 24px;
      color: #ffffff;
    }
  }

  .depositInformation {
    width: 100%;
    padding: 15px 0 0px;

    .minimumDepositAmount {
      display: flex;
      flex-direction: column;
      margin-bottom: 42px;
      span {
        font-size: 24px;
        font-weight: 400;
        line-height: 1.2;
      }

      .title {
        color: #999999;
      }
      b {
        font-size: 36px;
        font-weight: 500;
        color: #b4272b;
      }
    }

    .paymentFormBox,
    :deep(.paymentFormBox) {
      .van-cell-group {
        margin: 0;
      }

      .van-cell {
        padding: 0;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        &::after {
          display: none;
        }

        border: none;

        .van-cell__title {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          padding-top: 11px;
          width: 166px;
          font-size: 28px;

          font-weight: 400;
          color: #999999;

          &::before {
            content: "*";
            width: 20px;
            color: #b4272b;
          }
        }

        .van-field__body {
          textarea {
            width: 100%;
            height: 138px;
            background: #ffffff;
            border: 1px solid #dfdfdf;
            border-radius: 6px;
            padding: 15px 10px;
            font-size: 28px;
            font-weight: 400;
            color: #000000;
            line-height: 32px;
          }
        }
      }

      input[type="text"] {
        height: 70px;
        background: #ffffff;
        border: 1px solid #dfdfdf;
        border-radius: 6px;
        font-size: 28px;
        font-weight: 400;
        color: #000000;
      }

      .paymentAmount {
        position: relative;

        input {
          padding-right: 50px;
        }

        .yuan {
          position: absolute;
          right: 22px;
          font-size: 28px;
          color: #000000;
          border: none;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          justify-content: center;
          align-items: center;
          width: 25px;
        }
      }

      .optionalField {
        .van-cell__title {
          &::before {
            content: "";
          }
        }
      }
    }

    .RemittanceNotes {
      padding: 30px 0 0;
      width: 568px;

      h3 {
        font-size: 28px;
        font-weight: 600;
        color: #010101;
        margin-bottom: 29px;
      }

      p {
        font-size: 24px;
        line-height: 1.5;
        font-weight: 400;
        color: #010101;
        margin-bottom: 5px;
      }

      p.importantReminder {
        color: #b4272d;
        margin-top: 25px;
      }
    }
  }
}

:deep(.van-calendar__confirm) {
  span {
    font-size: 24px;
  }
}
</style>
